import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createRota, getRotaById, updateRotaById } from '../../services/rotaService';

// Helper function to format date to hh:mm
function formatTime(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    minutes = minutes.length < 2 ? '0' + minutes : minutes;
    hours = hours.length < 2 ? '0' + hours : hours;
    return hours + ':' + minutes;
}

function RotaForm() {
    const [rota, setRota] = useState({
        type: '',
        start_time: '',
        end_time: '',
        worker_id: ''
    });
    const { rota_id } = useParams();
    const navigate = useNavigate();
    const isNewRota = !rota_id;

    useEffect(() => {
        if (!isNewRota) {
            const fetchRota = async () => {
                try {
                    const fetchedRota = await getRotaById(rota_id);
                    const updatedRota = {
                        ...fetchedRota.data,
                        start_time: formatTime(fetchedRota.data.start_time),
                        end_time: formatTime(fetchedRota.data.end_time)
                    };
                    setRota(updatedRota);
                } catch (error) {
                    console.error('Failed to fetch rota details', error);
                }
            };
            fetchRota();
        }
    }, [rota_id, isNewRota]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'worker_id' && value === '') {
            setRota((prevRota) => ({
                ...prevRota,
                [name]: null 
            }));
        } else {
            setRota((prevRota) => ({
                ...prevRota,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (isNewRota) {
                await createRota(rota);
            } else {
                await updateRotaById(rota_id, rota);
            }
            navigate('/rotas');
        } catch (error) {
            console.error('Failed to submit rota', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>{isNewRota ? 'Create New Rota' : 'Edit Rota'}</h2>
            <div>
                <label>
                    Type:
                    <input
                        type="text"
                        name="type"
                        value={rota.type}
                        onChange={handleChange}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    Start Time:
                    <input
                        type="time"
                        name="start_time"
                        value={rota.start_time}
                        onChange={handleChange}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    End Time:
                    <input
                        type="time"
                        name="end_time"
                        value={rota.end_time}
                        onChange={handleChange}
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    Worker ID (optional):
                    <input
                        type="number"
                        name="worker_id"
                        value={rota.worker_id || ''} // Handle null by converting to an empty string
                        onChange={handleChange}
                    />
                </label>
            </div>
            <button type="submit">{isNewRota ? 'Create' : 'Update'} Rota</button>
        </form>
    );
}

export default RotaForm;
