import React, { useState, useEffect } from 'react';
import { getCurrentById } from '../../services/currentService';
import { useParams, Link } from 'react-router-dom';
import './CurrentDetailView.css';

function CurrentDetailView() {
  const [current, setCurrent] = useState(null);
  const { current_id } = useParams();

  useEffect(() => {
    const fetchCurrent = async () => {
      try {
        const response = await getCurrentById(current_id);
        setCurrent(response.data);
      } catch (error) {
        console.error('Error fetching current details', error);
      }
    };

    if (current_id) {
      fetchCurrent();
    }
  }, [current_id]);

  if (!current) return <div>Loading...</div>;

  return (
    <div>
      <h2 className="current-detail-view">Visit Details</h2>
      <p>Current ID: {current.current_id}</p>
      <p>Requirement type: {current.requirement_type}</p>
      <p>Timeslot: {current.timeslot}</p>
      <p>Start time: {current.start_time}</p>
      <p>Duration: {current.duration}</p>
      <p>Number of workers: {current.number_of_workers}</p>
      <p>Tasks required: {current.tasks_required}</p>
      <p>End date, estimated: {current.end_date_estimated}</p>
      <Link to="/currents">Back to Visit Demand List</Link>
      <Link to={`/current/edit/${current.current_id}`} style={{marginLeft: '10px'}}>Edit Visit Demand</Link>
    </div>
  );

}

export default CurrentDetailView;
