import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCurrentById, createCurrent, updateCurrentById } from '../../services/currentService';

function CurrentForm() {
  const [current, setCurrent] = useState({
    requirement_type: 'timeslot_preferred', // Set default requirement_type
    timeslot: '',
    start_time: '',
    duration: '',
    number_of_workers: '',
    tasks_required: '',
    end_date_estimated: '',
    resident_id: '',
  });
  const params = useParams();
  const navigate = useNavigate();
  const isNewCurrent = !params.current_id;

  useEffect(() => {
    if (!isNewCurrent) {
        getCurrentById(params.current_id)
            .then(response => {
                const fetchedData = response.data;
                // Exclude fields like password for security, if necessary
                // delete fetchedData.password;

                // Format start_time if it exists
                if (fetchedData.start_time) {
                    const startTime = new Date(fetchedData.start_time);
                    fetchedData.start_time = startTime.toISOString().substring(11, 16); // "HH:MM"
                }

                // Format end_date_estimated if it exists
                if (fetchedData.end_date_estimated) {
                    const endDate = new Date(fetchedData.end_date_estimated);
                    fetchedData.end_date_estimated = endDate.toISOString().substring(0, 10); // "YYYY-MM-DD"
                }

                setCurrent(fetchedData);
            })
            .catch(error => console.error('Error fetching current details', error));
    }
  }, [params.current_id, isNewCurrent]);

  const handleChange = event => {
    const { name, value } = event.target;
    let updatedValues = { [name]: value };

    if (name === 'number_of_workers') {
        if (value === '2') {
            // For double-handed visits, set requirement_type to 'start_time'
            updatedValues['requirement_type'] = 'start_time';
        }
    }

    // Handling mutually exclusive fields
    if (name === 'requirement_type') {
        if (value === 'start_time') {
            updatedValues.timeslot = null;
        } else if (value.includes('timeslot')) {
            updatedValues.start_time = null;
        }
    } else if (name === 'timeslot' && value !== '') {
        updatedValues.start_time = null;
    } else if (name === 'start_time' && value !== '') {
        updatedValues.timeslot = null;
    }

    setCurrent(prevState => ({
        ...prevState,
        ...updatedValues
    }));
};



  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting form with current data: ', current);
    try {
      let response;
      if (isNewCurrent) {
        response = await createCurrent(current);
      } else {
        response = await updateCurrentById(params.current_id, current);
      }
      console.log('Current saved, API response:', response);
      navigate('/currents');
    } catch (error) {
      console.error('Error saving current', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>{isNewCurrent ? 'Create New Visit Demand' : 'Edit Visit Demand'}</h2>
      <div>
        <label>Requirement type:</label>
        <select name="requirement_type" value={current.requirement_type} onChange={handleChange}>
          <option value="start_time">Start time</option>
          <option value="timeslot_required">Timeslot required</option>
          <option value="timeslot_preferred">Timeslot preferred</option>
        </select>
      </div>
      {/* Conditionally render Timeslot or Start time based on requirement_type */}
      {current.requirement_type !== 'start_time' && (
        <div>
          <label>Timeslot:</label>
          <select name="timeslot" value={current.timeslot || ''} onChange={handleChange}>
            <option value="">Select timeslot</option>
            <option value="AM">AM</option>
            <option value="Lunch">Lunch</option>
            <option value="PM">PM</option>
            <option value="Evening">Evening</option>
          </select>
        </div>
      )}
      {current.requirement_type === 'start_time' && (
        <div>
          <label>Start time:</label>
          <input type="time" name="start_time" value={current.start_time} onChange={handleChange} />
        </div>
      )}
      <div>
        <label>Duration:</label>
        <input type="number" name="duration" value={current.duration} onChange={handleChange} />
      </div>
      <div>
          <label>Number of workers:</label>
          <select name="number_of_workers" value={current.number_of_workers || ''} onChange={handleChange}>
              <option value="">Select number of workers</option>
              <option value="1">1</option>
              <option value="2">2</option>
          </select>
      </div>
      <div>
        <label>Tasks required:</label>
        <input type="text" name="tasks_required" value={current.tasks_required} onChange={handleChange}  />
      </div>
      <div>
        <label>End date estimated:</label>
        <input type="date" name="end_date_estimated" value={current.end_date_estimated} onChange={handleChange}  />
      </div>
      <div>
        <label>Resident ID:</label>
        <input type="number" name="resident_id" value={current.resident_id} onChange={handleChange}  />
      </div>
      <button type="submit">{isNewCurrent ? 'Create' : 'Update'} Visit Demand</button>
    </form>
  );
}

export default CurrentForm;