// UserDropdown.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';  

const UserDropdown = () => {
  const { currentUser, logout } = useAuth();
  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  return (
    <div className="dropdown-content">
      {!currentUser ? (
        <>
          <Link to="/login">Login</Link>
          <Link to="/register">Register</Link>
        </>
      ) : (
        <>
          <a href="/" onClick={handleLogout}>Logout</a> 
        </>
      )}
    </div>
  );
};

export default UserDropdown;
