import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BACKEND_API_URL}/currents`;

// Helper function to get token
const getAuthToken = () => localStorage.getItem('token'); // Assuming the token is stored with the key 'token'

// Helper function to attach the authorization header to requests
const authHeader = () => {
  const token = getAuthToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getAllCurrents = () => axios.get(API_URL, { headers: authHeader() });
export const getCurrentById = (currentId) => axios.get(`${API_URL}/${currentId}`, { headers: authHeader() });
export const createCurrent = (currentData) => axios.post(API_URL, currentData, { headers: authHeader() });
export const updateCurrentById = (currentId, currentData) => axios.put(`${API_URL}/${currentId}`, currentData, { headers: authHeader() });
export const deleteCurrentById = (currentId) => axios.delete(`${API_URL}/${currentId}`, { headers: authHeader() });
export const getCurrentsByResidentId = (residentId) => axios.get(`${API_URL}/by-resident/${residentId}`, { headers: authHeader() });
