import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllCurrents, deleteCurrentById } from '../../services/currentService';
import './CurrentListView.css'; 

function CurrentListView() {
    const [currents, setCurrents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    // Fetch all currents
    useEffect(() => {
        getAllCurrents().then(response => {
            setCurrents(response.data);
            setLoading(false);
            setError('');
        }).catch(err => {
            console.error(err);
            setError('Failed to fetch currents');
            setLoading(false);
        });
    }, []);

    // Sorting logic
    const sortedCurrents = React.useMemo(() => {
        return [...currents].sort((a, b) => {
            if (!sortField) return 0; // No sorting applied if sortField is not set
            const valueA = a[sortField];
            const valueB = b[sortField];
            return (valueA < valueB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });
    }, [currents, sortField, sortDirection]);

    // Function to request a sort based on the field
    const requestSort = (key) => {
        const direction = sortField === key && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(key);
        setSortDirection(direction);
    };

    // Delete a current (visit)
    const handleDelete = (currentId) => {
        if (window.confirm('Are you sure you want to delete this visit demand?')) {
            deleteCurrentById(currentId).then(() => {
                setCurrents(currents.filter((current) => current.current_id !== currentId));
            }).catch(err => {
                console.error(err);
                setError('Failed to delete visit demand');
            });
        }
    };

    // Utility function to format start_time as "HH:mm"
    const formatStartTime = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    // Utility function to format end_date_estimated as "dd/mm/yyyy"
    const formatEndDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        return date.toLocaleDateString('en-GB'); // en-GB format gives you "dd/mm/yyyy"
    };

    if (loading) return <div>Loading currents...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h2>Visit demand</h2>
            <Link to="/current/new" className="create-new-current">Create New Visit Demand</Link>
            <table className="currents-table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('current_id')}>CurrentID {sortField === 'current_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('requirement_type')}>Requirement Type {sortField === 'requirement_type' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('timeslot')}>Timeslot {sortField === 'timeslot' && (sortDirection === 'asc' ? '▲' : '▼')}</th>                        
                        <th onClick={() => requestSort('start_time')}>Start Time {sortField === 'start_time' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('duration')}>Duration {sortField === 'duration' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('number_of_workers')}>Number of workers {sortField === 'number_of_workers' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('tasks_required')}>Tasks required {sortField === 'tasks_required' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('end_date_estimated')}>End date, estimated {sortField === 'end_date_estimated' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('resident_id')}>Resident ID {sortField === 'resident_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th className="actions-column">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedCurrents.map((current) => (
                        <tr key={current.current_id}>
                            <td>{current.current_id}</td>
                            <td>{current.requirement_type}</td>
                            <td>{current.timeslot}</td>
                            <td>{formatStartTime(current.start_time)}</td>
                            <td>{current.duration}</td>                            
                            <td>{current.number_of_workers}</td>
                            <td>{current.tasks_required}</td>
                            <td>{formatEndDate(current.end_date_estimated)}</td>
                            <td>{current.resident_id}</td>
                            <td className="actions-column">
                            <Link to={`/current/${current.current_id}`} className="view-details">View Details</Link>
                            <Link to={`/current/edit/${current.current_id}`} className="edit">Edit</Link>
                            <Link onClick={() => handleDelete(current.current_id)} className="delete">Delete</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CurrentListView;
