import React, { useState, useEffect } from 'react';
import { getWorker } from '../../services/workerService';
import { useParams, Link } from 'react-router-dom';
import './WorkerDetailView.css'; // Make sure to import the CSS file

function WorkerDetailView() {
  const [worker, setWorker] = useState(null);
  const { worker_id } = useParams();

  useEffect(() => {
    const fetchWorker = async () => {
      try {
        const response = await getWorker(worker_id);
        setWorker(response.data);
      } catch (error) {
        console.error('Error fetching worker details', error);
      }
    };

    if (worker_id) {
      fetchWorker();
    }
  }, [worker_id]);

  if (!worker) return <div>Loading...</div>;

  return (
    <div>
      <h2 className="worker-detail-view">Worker Details</h2>
      <p>Worker ID: {worker.worker_id}</p>
      <p>Name: {worker.first_name} {worker.last_name}</p>
      <p>Email: {worker.email}</p>
      <p>Role: {worker.role}</p>
      <p>Date joined: {new Date(worker.date_of_joining).toLocaleDateString()}</p>
      <p>Team ID: {worker.team_id}</p>
      <Link to="/workers">Back to Workers List</Link>
      <Link to={`/worker/edit/${worker.worker_id}`} style={{marginLeft: '10px'}}>Edit Worker</Link>
    </div>
  );

}

export default WorkerDetailView;
