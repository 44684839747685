import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getWorker, createWorker, updateWorker } from '../../services/workerService';



function WorkerForm() {
  const [worker, setWorker] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    date_of_joining: '',
    team_id: '',
    password: '',
  });
  const params = useParams();
  const navigate = useNavigate();
  const isNewWorker = !params.worker_id;

  useEffect(() => {
    if (!isNewWorker) {
      // Fetch worker details if editing an existing worker
      getWorker(params.worker_id)
        .then(response => {
          setWorker({ ...response.data, password: '' }); // Exclude password for security
        })
        .catch(error => console.error('Error fetching worker details', error));
    }
  }, [params.worker_id, isNewWorker]);

  const handleChange = event => {
    const { name, value } = event.target;
    setWorker(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting form with worker data: ', worker); // Log the worker data to be submitted
    try {
      let response;
      if (isNewWorker) {
        response = await createWorker(worker);
      } else {
        response = await updateWorker(params.worker_id, worker);
      }
      console.log('Worker saved, API response:', response); // Log the API response
      navigate('/workers'); // Redirect to the list view
    } catch (error) {
      console.error('Error saving worker', error); // Log any error that occurs during the save operation
      // Optionally, you can add a state to show the error message on the form
    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <h2>{isNewWorker ? 'Create New Worker' : 'Edit Worker'}</h2>
      <div>
        <label>First Name: </label>
        <input type="text" name="first_name" value={worker.first_name} onChange={handleChange} required />
      </div>
      <div>
        <label>Last Name: </label>
        <input type="text" name="last_name" value={worker.last_name} onChange={handleChange} required />
      </div>
      <div>
        <label>Email: </label>
        <input type="email" name="email" value={worker.email} onChange={handleChange} required />
      </div>
      <div>
        <label>Role: </label>
        <input type="text" name="role" value={worker.role} onChange={handleChange} required />
      </div>
      <div>
        <label>Date of Joining: </label>
        <input type="date" name="date_of_joining" value={worker.date_of_joining} onChange={handleChange} required />
      </div>
      <div>
          <label>Team ID:</label>
          <select name="team_id" value={worker.team_id || ''} onChange={handleChange}>
            <option value="">Select team</option>
            <option value="team1">team1</option>
            <option value="team2">team2</option>
            <option value="team3">team3</option>
            <option value="team4">team4</option>
          </select>
        </div>
      <button type="submit">{isNewWorker ? 'Create' : 'Update'} Worker</button>
    </form>
  );
}

export default WorkerForm;
