import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
// import { useAuth } from '../../contexts/AuthContext';
import './LoginRegister.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  // const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
        alert('Email and password are required');
        return;
    }
    console.log('Login with:', email, password);

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/auth/login`, { email, password });
        console.log('Login successful:', response.data);
        // Directly save the token to local storage
        localStorage.setItem('token', response.data.token);
        navigate('/'); 
    } catch (error) {
        alert('Login failed: ' + (error.response ? error.response.data.message : error.message));
    }
};

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <label>
          Email:
          <input type="email" className="form-input" value={email} onChange={e => setEmail(e.target.value)} />
        </label>
        <label>
          Password:
          <input type="password" className="form-input" value={password} onChange={e => setPassword(e.target.value)} />
        </label>
        <button type="submit" className="login-button">Log In</button>
      </form>
    </div>
  );
}

export default Login;
