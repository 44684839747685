import React from 'react';
import './Home.css';

const Home = () => {

    return (
        <html>
            <h1>Welcome to amelio</h1>
            <h2 className="home-paragraph">Browse using the menu above.</h2>
        </html>
    );
};

export default Home;