import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BACKEND_API_URL}/services/`;

const authHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const triggerScheduler = async () => {
    try {
        const response = await axios.post(`${BASE_URL}trigger-scheduler`, {}, { headers: authHeader() });
        return response.data;
    } catch (error) {
        console.error('Error triggering scheduler:', error);
        throw error;
    }
};
