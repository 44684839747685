import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './RotaCalendarView.css'; 

import { getAllRotas } from '../../services/rotaService';

const localizer = momentLocalizer(moment);

function RotaCalendarView() {
    const [rotas, setRotas] = useState([]);

    useEffect(() => {
        const fetchRotas = async () => {
            try {
                const response = await getAllRotas();
                const formattedRotas = response.data.map((rota) => ({
                    ...rota,
                    title: rota.type,
                    start: new Date(rota.date + 'T' + rota.start_time),
                    end: new Date(rota.date + 'T' + rota.end_time),
                }));
                setRotas(formattedRotas);
            } catch (error) {
                console.error('Failed to fetch rotas', error);
            }
        };

        fetchRotas();
    }, []);

    return (
        <div className="rota-calendar-container" style={{ height: '800px', margin: '0 auto', maxWidth: '90%' }}>
            <Calendar
                localizer={localizer}
                events={rotas}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
            />
        </div>
    );
}

export default RotaCalendarView;