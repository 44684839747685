import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BACKEND_API_URL}/rotas`;

const authHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getAllRotas = () => axios.get(API_URL, { headers: authHeader() });
export const getRotaById = (rotaId) => axios.get(`${API_URL}/${rotaId}`, { headers: authHeader() });
export const createRota = (rotaData) => axios.post(API_URL, rotaData, { headers: authHeader() });
export const updateRotaById = (rotaId, rotaData) => axios.put(`${API_URL}/${rotaId}`, rotaData, { headers: authHeader() });
export const deleteRotaById = (rotaId) => axios.delete(`${API_URL}/${rotaId}`, { headers: authHeader() });
