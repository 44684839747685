import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllRotas, deleteRotaById } from '../../services/rotaService';
import './RotaListView.css';

// Helper function to format date to hh:mm
function formatTime(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();

    // Pad single digit minutes with a leading zero
    minutes = minutes.length < 2 ? '0' + minutes : minutes;
    // Pad single digit hours with a leading zero
    hours = hours.length < 2 ? '0' + hours : hours;

    return hours + ':' + minutes;
}

function RotaListView() {
    const [rotas, setRotas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    // Fetch all rotas
    useEffect(() => {
        getAllRotas().then(response => {
            setRotas(response.data);
            setLoading(false);
            setError('');
        }).catch(err => {
            console.error(err);
            setError('Failed to fetch rotas');
            setLoading(false);
        });
    }, []);

    // Delete a rota
    const handleDelete = (rotaId) => {
        if (window.confirm('Are you sure you want to delete this rota?')) {
            deleteRotaById(rotaId).then(() => {
                setRotas(rotas.filter((rota) => rota.rota_id !== rotaId));
            }).catch(err => {
                console.error(err);
                setError('Failed to delete rota');
            });
        }
    };

    const sortedRotas = React.useMemo(() => {
        return [...rotas].sort((a, b) => {
            if (!sortField) return 0; 
            const valueA = a[sortField];
            const valueB = b[sortField];
            return (valueA < valueB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });
    }, [rotas, sortField, sortDirection]);

    const requestSort = (key) => {
        const direction = sortField === key && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(key);
        setSortDirection(direction);
    };

    if (loading) return <div>Loading rotas...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        
        <div className="rota-list-view">
            <h2>Rotas</h2>
            <Link to="/rota/new" className="create-new-rota">Create New Rota</Link>
            {/* <Link to="/rota-calendar" className="create-new-rota">See Calendar</Link> */}
            <table className="rotas-table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('rota_id')}>RotaID {sortField === 'rota_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('type')}>Type {sortField === 'type' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('start_tme')}>Start Time {sortField === 'start_time' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('end_time')}>End Time {sortField === 'end_time' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('worker_id')}>Worker ID {sortField === 'worker_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedRotas.map((rota) => (
                        <tr key={rota.rota_id}>
                            <td>{rota.rota_id}</td>
                            <td>{rota.type}</td>
                            <td>{formatTime(rota.start_time)}</td>  
                            <td>{formatTime(rota.end_time)}</td>   
                            <td>{rota.worker_id || 'Unassigned'}</td>
                            <td>
                                <Link to={`/rota/${rota.rota_id}`} className="view-details">View Details</Link>
                                <Link to={`/rota/edit/${rota.rota_id}`} className="edit">Edit</Link>
                                <Link onClick={() => handleDelete(rota.rota_id)} className="delete">Delete</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default RotaListView;