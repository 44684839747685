import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; 

const Navbar = () => {
    const [resourceDropdownOpen, setResourceDropdownOpen] = useState(false);

    return (
        <nav className='navbar'>
            <ul>
                <li><Link to="/"><img src={`${process.env.PUBLIC_URL}/logo-icon-purple-transparent.png`} alt="Logo" className="App-logo" /></Link></li>
                
                <li className="resource-dropdown" onClick={() => setResourceDropdownOpen(!resourceDropdownOpen)}> 
                    Resource
                    {resourceDropdownOpen && (
                        <div className="dropdown-content"> 
                            <Link to="/workers">Workers</Link>
                            <Link to="/rotas">Rotas</Link>
                        </div>
                    )}
                </li>

                <li className="resource-dropdown" onClick={() => setResourceDropdownOpen(!resourceDropdownOpen)}> 
                    Caseload
                    {resourceDropdownOpen && (
                        <div className="dropdown-content"> 
                            <Link to="/residents">Residents</Link>
                            <Link to="/currents">Visit demand</Link>
                        </div>
                    )}
                </li>

                <li className="resource-dropdown"><Link to="/appointments">Schedule</Link></li>

            </ul>
        </nav>
    );
};

export default Navbar;