import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginRegister.css'; // Assuming this is the CSS file for styling

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [teamId, setTeamId] = useState('');
    const navigate = useNavigate();


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !password || !confirmPassword || !firstName || !lastName || !role || !teamId) {
            alert('All fields are required');
            return;
        }
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/auth/register`, {
                email: email,
                password: password,
                first_name: firstName, 
                last_name: lastName,  
                role: role,
                team_id: teamId,       
            });
            console.log('Registration successful:', response.data);
            navigate('/login'); // Redirect to login page after successful registration
        } catch (error) {
            alert('Registration failed: ' + (error.response ? error.response.data.message : error.message));
        }
    };

    return (
        <div className="login-container">
            <h2>Register</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    First Name:
                    <input type="text" className="form-input" value={firstName} onChange={e => setFirstName(e.target.value)} />
                </label>
                <label>
                    Last Name:
                    <input type="text" className="form-input" value={lastName} onChange={e => setLastName(e.target.value)} />
                </label>
                <label>
                    Email:
                    <input type="email" className="form-input" value={email} onChange={e => setEmail(e.target.value)} />
                </label>
                <label>
                    Password:
                    <input type="password" className="form-input" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
                <label>
                    Confirm Password:
                    <input type="password" className="form-input" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                </label>
                <label>
                    Role:
                    <select className="form-input" value={role} onChange={e => setRole(e.target.value)}>
                        <option value="">Select Role</option>
                        <option value="Team manager">Manager</option>
                        <option value="Worker">Worker</option>
                        <option value="Admin">Admin</option>
                    </select>
                </label>
                <label>
                    Team:
                    <select className="form-input" value={teamId} onChange={e => setTeamId(e.target.value)}>
                        <option value="">Select Team</option>
                        <option value="team1">Team 1</option>
                        <option value="team2">Team 2</option>
                        <option value="team3">Team 3</option>
                    </select>
                </label>
                <button type="submit" className="login-button">Register</button>
            </form>
        </div>
    );
}

export default Register;