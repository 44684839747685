import axios from 'axios';

const EXPORT_API_URL = `${process.env.REACT_APP_BACKEND_API_URL}/appointments/export`;

// Helper function to get token
const getAuthToken = () => localStorage.getItem('token');

// Helper function to attach the authorization header to requests
const authHeader = () => {
  const token = getAuthToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const exportAppointmentsToCSV = async () => {
    try {
        const response = await axios.get(EXPORT_API_URL, {
            responseType: 'blob',
            headers: authHeader(),
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        const date = new Date();
        const dateString = date.toISOString().replace(/T/, '-').replace(/\..+/, '').replace(/:/g, '');

        link.href = url;
        link.setAttribute('download', `${dateString}-appointments.csv`);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        return { success: true };
    } catch (error) {
        console.error('Failed to export appointments:', error);
        return { success: false, error: error.message };
    }
};
