import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BACKEND_API_URL}/appointments`;

const getAuthToken = () => {
    return localStorage.getItem('token'); // Assuming the token is stored with the key 'token'
  };

// Helper function to attach the authorization header to requests
const authHeader = () => {
    const token = getAuthToken();
    if (token) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  };

export const getAllAppointments = () => axios.get(API_URL, { headers: authHeader() });
export const deleteAllAppointments = () => axios.delete(API_URL, { headers: authHeader() });
export const getAppointmentById = (appointmentId) => axios.get(`${API_URL}/${appointmentId}`, { headers: authHeader() });
export const createAppointment = (appointmentData) => axios.post(API_URL, appointmentData, { headers: authHeader() });
export const updateAppointmentById = (appointmentId, appointmentData) => axios.put(`${API_URL}/${appointmentId}`, appointmentData, { headers: authHeader() });
export const deleteAppointmentById = (appointmentId) => axios.delete(`${API_URL}/${appointmentId}`, { headers: authHeader() });