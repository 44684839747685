import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllResidents, deleteResidentById } from '../../services/residentService';
import { getCurrentsByResidentId } from '../../services/currentService';
import { getSettingsById } from '../../services/settingService';

import './ResidentListView.css';

function ResidentListView() {
    const [residents, setResidents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    // Initializing timeslotDefinitions with safe default values
    const [timeslotDefinitions, setTimeslotDefinitions] = useState({
        AM: { start: '00:00', end: '00:00' },
        Lunch: { start: '00:00', end: '00:00' },
        PM: { start: '00:00', end: '00:00' },
        Evening: { start: '00:00', end: '00:00' }
    });

    useEffect(() => {
        let mounted = true;

        const fetchSettingsAndResidents = async () => {
            try {
                const settingsConfigId = "1"; // Assuming ID 1 for the example
                const settingsResponse = await getSettingsById(settingsConfigId);
                if (settingsResponse.data) {
                    const settings = settingsResponse.data;
                    setTimeslotDefinitions({
                        AM: { start: settings.AM_shift_start.slice(11, 16), end: settings.AM_shift_end.slice(11, 16) },
                        Lunch: { start: settings.lunch_shift_start.slice(11, 16), end: settings.lunch_shift_end.slice(11, 16) },
                        PM: { start: settings.PM_shift_start.slice(11, 16), end: settings.PM_shift_end.slice(11, 16) },
                        Evening: { start: settings.evening_shift_start.slice(11, 16), end: settings.evening_shift_end.slice(11, 16) }
                    });
                }

                const residentsResponse = await getAllResidents();
                if (mounted && residentsResponse.data) {
                    const residentsWithCurrents = await Promise.all(residentsResponse.data.map(async (resident) => {
                        try {
                            const currentsResponse = await getCurrentsByResidentId(resident.resident_id);
                            return { ...resident, currents: currentsResponse.data };
                        } catch (error) {
                            console.error('Failed to fetch currents for resident:', error);
                            return { ...resident, currents: [] };
                        }
                    }));
                    setResidents(residentsWithCurrents);
                }
            } catch (error) {
                console.error('Failed to fetch data', error);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchSettingsAndResidents();

        return () => {
            mounted = false;
        };
    }, []);

        // Delete a resident
        const handleDelete = (residentId) => {
            if (window.confirm('Are you sure you want to delete this resident?')) {
                deleteResidentById(residentId).then(() => {
                    setResidents(residents.filter((resident) => resident.resident_id !== residentId));
                }).catch(err => {
                    console.error(err);
                    setError('Failed to delete resident');
                });
            }
        };
    
        const formatTime = (dateTimeString) => {
            const date = new Date(dateTimeString);
            // Format hours and minutes: padStart ensures two digits
            let hours = String(date.getHours()).padStart(2, '0');
            let minutes = String(date.getMinutes()).padStart(2, '0');
            return `${hours}:${minutes}`; // returns HH:MM format
        };
    
        const isInTimeslot = (current, timeslotRange) => {
            // Ensure 'current' has a 'requirement_type' and 'start_time', and 'start_time' is a string
            if (current.requirement_type === 'start_time' && typeof current.start_time === 'string' && current.start_time) {
                // Extract the time component from the ISO 8601 format
                const dateTimeString = current.start_time;
                const timeParts = dateTimeString.split('T');
                // Ensure that the 'T' split produces an array with at least two elements
                if (timeParts.length < 2) {
                    console.error('Invalid start_time format', current.start_time);
                    return false;
                }
                const timeString = timeParts[1].split('.')[0]; // Removes milliseconds and 'Z', ensure this is valid
                const [startHour, startMinute] = timeString.split(':').map(Number);
                // Validate that startHour and startMinute are numbers
                if (isNaN(startHour) || isNaN(startMinute)) {
                    console.error('Invalid time in start_time', current.start_time);
                    return false;
                }
                const startTimeMinutes = startHour * 60 + startMinute;
                
                const [timeslotStartHour, timeslotStartMinute] = timeslotRange.start.split(':').map(Number);
                const timeslotStartMinutes = timeslotStartHour * 60 + timeslotStartMinute;
                
                const [timeslotEndHour, timeslotEndMinute] = timeslotRange.end.split(':').map(Number);
                const timeslotEndMinutes = timeslotEndHour * 60 + timeslotEndMinute;
                
                return startTimeMinutes >= timeslotStartMinutes && startTimeMinutes < timeslotEndMinutes;
            }
        
            return false;
        };
    
        const TimeslotCell = ({ currents, timeslot, timeslotRanges }) => {
            // Filter visits for those that match the current timeslot or whose start_time falls within it.
            const filteredVisits = currents.filter(current => {
                // Direct match for timeslot preference or requirement.
                if (current.timeslot === timeslot) {
                    return true;
                }
                
                // For start_time visits, check if the time falls within the timeslot range.
                if (current.requirement_type === 'start_time') {
                    return isInTimeslot(current, timeslotRanges[timeslot]);
                }
        
                return false;
            });
    
            if (loading) return <div>Loading residents...</div>;
            if (error) return <div>Error: {error}</div>;
        
            return (
                <div className="timeslot-cell">
                    {filteredVisits.map((current, index) => (
                        <div key={index} className="current-details">
                            <div className="indicator-container">
                                <span className={`circle ${current.requirement_type === 'start_time' ? 'red' : current.requirement_type === 'timeslot_required' ? 'pink' : 'white'}`}></span>
                                {current.number_of_workers > 1 && (
                                    <span className="square">{current.number_of_workers}</span>
                                )}
                            </div>
                            <div>
                                {current.requirement_type === 'start_time' && (
                                    <span className="time-text">{formatTime(current.start_time)}</span>
                                )}
                                <span>{current.duration} mins</span>
                            </div>
                        </div>
                    ))}
                </div>
            );
        };
    
        const sortedResidents = React.useMemo(() => {
            return [...residents].sort((a, b) => {
                if (!sortField) return 0; 
                const valueA = a[sortField];
                const valueB = b[sortField];
                return (valueA < valueB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
            });
        }, [residents, sortField, sortDirection]);
    
        const requestSort = (key) => {
            const direction = sortField === key && sortDirection === 'asc' ? 'desc' : 'asc';
            setSortField(key);
            setSortDirection(direction);
    
        if (loading) return <div>Loading residents...</div>;
        if (error) return <div>Error: {error}</div>;
        }

    return (
        
        <div className="resident-list-view">
            <h1>Residents</h1>
            <Link to="/resident/new" className="create-new-resident">Create New Resident</Link>
            <table className="residents-table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('resident_id')}>Resident ID {sortField === 'resident_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('first_name')}>First Name {sortField === 'first_name' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('last_name')}>Last Name {sortField === 'last_name' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        {/* <th onClick={() => requestSort('contact number')}>Contact number</th> */}
                        {/* <th onClick={() => requestSort('date_of_birth')}>DoB</th> */}
                        {/* <th onClick={() => requestSort('address_line_1')}>Address Line 1</th> */}
                        {/* <th onClick={() => requestSort('address_line_2')}>Address Line 2</th> */}
                        {/* <th onClick={() => requestSort('city')}>City</th> */}
                        <th onClick={() => requestSort('postcode')}>Postcode {sortField === 'postcode' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        {/* <th onClick={() => requestSort('country')}>Country</th> */}
                        <th className="timeslot-column">AM <span className="timeslot-time">{`${timeslotDefinitions.AM.start}-${timeslotDefinitions.AM.end}`}</span></th>
                        <th className="timeslot-column">Lunch <span className="timeslot-time">{`${timeslotDefinitions.Lunch.start}-${timeslotDefinitions.Lunch.end}`}</span></th>
                        <th className="timeslot-column">PM <span className="timeslot-time">{`${timeslotDefinitions.PM.start}-${timeslotDefinitions.PM.end}`}</span></th>
                        <th className="timeslot-column">Evening <span className="timeslot-time">{`${timeslotDefinitions.Evening.start}-${timeslotDefinitions.Evening.end}`}</span></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedResidents.map(resident => (
                        <tr key={resident.resident_id}>
                            <td>{resident.resident_id}</td>
                            <td>{resident.first_name}</td>  
                            <td>{resident.last_name}</td>
                            {/* <td>{resident.contact_number}</td> */}
                            {/* <td>{resident.date_of_birth}</td> */}
                            {/* <td>{resident.address_line_1}</td> */}
                            {/* <td>{resident.address_line_2}</td> */}
                            {/* <td>{resident.city}</td> */}
                            <td>{resident.postcode}</td>
                            {/* <td>{resident.country}</td> */}
                            <td><TimeslotCell currents={resident.currents || []} timeslot="AM" timeslotRanges={timeslotDefinitions} /></td>
                            <td><TimeslotCell currents={resident.currents || []} timeslot="Lunch" timeslotRanges={timeslotDefinitions} /></td>
                            <td><TimeslotCell currents={resident.currents || []} timeslot="PM" timeslotRanges={timeslotDefinitions} /></td>
                            <td><TimeslotCell currents={resident.currents || []} timeslot="Evening" timeslotRanges={timeslotDefinitions} /></td>
                            <td>
                                <Link to={`/resident/${resident.resident_id}`} className="view-details">View Details</Link>
                                <Link to={`/resident/edit/${resident.resident_id}`} className="edit">Edit</Link>
                                <Link onClick={() => handleDelete(resident.resident_id)} className="delete">Delete</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ResidentListView;