import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getResidentById } from '../../services/residentService';

import './ResidentDetailView.css';


function ResidentDetailView() {
  const [resident, setResident] = useState(null);
  const { resident_id } = useParams();

  useEffect(() => {
    const fetchResident = async () => {
      try {
        const response = await getResidentById(resident_id);
        setResident(response.data);
      } catch (error) {
        console.error('Error fetching resident details', error);
      }
    };

    if (resident_id) {
      fetchResident();
    }
  }, [resident_id]);

  if (!resident) return <div>Loading...</div>;

  return (
    <div>
      <h2 className="resident-detail-view">Resident Details</h2>
      <p>Resident ID: {resident.resident_id}</p>
      <p>Name: {resident.first_name} {resident.last_name}</p>
      <p>Contact number: {resident.contact_number}</p>
      <p>Date of birth: {resident.date_of_birth}</p>
      <p>Address: {`${resident.address_line_1 ? resident.address_line_1 + ', ' : ''}${resident.address_line_2 ? resident.address_line_2 + ', ' : ''}${resident.city ? resident.city + ', ' : ''}${resident.postcode ? resident.postcode + ', ' : ''}${resident.country ? resident.country : ''}`} </p>
      <Link to="/residents">Back to Residents List</Link>
      <Link to={`/resident/edit/${resident.resident_id}`} style={{marginLeft: '10px'}}>Edit Resident</Link>
    </div>
  );

}

export default ResidentDetailView;
