import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_API_URL}/workers`; 

const authHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getWorkers = () => axios.get(API_BASE_URL, { headers: authHeader() });
export const getWorker = (id) => axios.get(`${API_BASE_URL}/${id}`, { headers: authHeader() });
export const createWorker = (workerData) => axios.post(API_BASE_URL, workerData, { headers: authHeader() });
export const updateWorker = (id, workerData) => axios.put(`${API_BASE_URL}/${id}`, workerData, { headers: authHeader() });
export const deleteWorker = (id) => axios.delete(`${API_BASE_URL}/${id}`, { headers: authHeader() });
