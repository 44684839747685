import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Home from './components/homepage/Home';
import WorkerListView from './components/workers/WorkerListView';
import WorkerDetailView from './components/workers/WorkerDetailView';
import WorkerForm from './components/workers/WorkerForm';
import RotaListView from './components/rotas/RotaListView';
import RotaForm from './components/rotas/RotaForm';
import RotaDetailView from './components/rotas/RotaDetailView';
import RotaCalendarView from './components/rotas/RotaCalendarView';
import ResidentListView from './components/residents/ResidentListView';
import ResidentDetailView from './components/residents/ResidentDetailView';
import ResidentForm from './components/residents/ResidentForm';
import AppointmentListView from './components/appointments/AppointmentListView';
import CurrentListView from './components/currents/CurrentListView';
import CurrentDetailView from './components/currents/CurrentDetailView';
import CurrentForm from './components/currents/CurrentForm';
import SettingsForm from './components/settings/SettingForm';
import ProtectedRoute from './components/routeProtector/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext'; // Removed useAuth here since it's incorrectly placed
import UserDropdown from './components/auth/UserDropdown';
import './App.css';

function App() {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <Router>
            <AuthProvider>
                <div className="App">
                    <header className="App-header">
                        <div className="logo-and-nav">
                            <Navbar />
                        </div>
                        <div className="settings-and-user">
                            <Link to="/settings" className="settings-icon">
                                <i className="fas fa-cog"></i> {/* Settings icon */}
                            </Link>
                            <div className="user-icon" onClick={toggleDropdown}>
                                <i className="fas fa-user-circle"></i> {/* User icon */}
                                {dropdownOpen && <UserDropdown />}
                            </div>
                        </div>
                    </header>
                    
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/workers" element={<ProtectedRoute><WorkerListView /></ProtectedRoute>} />
                        <Route path="/worker/new" element={<ProtectedRoute><WorkerForm /></ProtectedRoute>} />
                        <Route path="/worker/edit/:worker_id" element={<ProtectedRoute><WorkerForm /></ProtectedRoute>} />
                        <Route path="/worker/:worker_id" element={<ProtectedRoute><WorkerDetailView /></ProtectedRoute>} />
                        <Route path="/rotas" element={<ProtectedRoute><RotaListView /></ProtectedRoute>} />
                        <Route path="/rota/new" element={<ProtectedRoute><RotaForm /></ProtectedRoute>} />
                        <Route path="/rota/edit/:rota_id" element={<ProtectedRoute><RotaForm /></ProtectedRoute>} />
                        <Route path="/rota/:rota_id" element={<ProtectedRoute><RotaDetailView /></ProtectedRoute>} />
                        <Route path="/rota-calendar" element={<ProtectedRoute><RotaCalendarView /></ProtectedRoute>} />
                        <Route path="/residents" element={<ProtectedRoute><ResidentListView /></ProtectedRoute>} />
                        <Route path="/resident/new" element={<ProtectedRoute><ResidentForm /></ProtectedRoute>} />
                        <Route path="/resident/edit/:resident_id" element={<ProtectedRoute><ResidentForm /></ProtectedRoute>} />
                        <Route path="/resident/:resident_id" element={<ProtectedRoute><ResidentDetailView /></ProtectedRoute>} />
                        <Route path="/appointments" element={<ProtectedRoute><AppointmentListView /></ProtectedRoute>} />
                        <Route path="/currents" element={<ProtectedRoute><CurrentListView /></ProtectedRoute>} />
                        <Route path="/current/new" element={<ProtectedRoute><CurrentForm /></ProtectedRoute>} />
                        <Route path="/current/edit/:current_id" element={<ProtectedRoute><CurrentForm /></ProtectedRoute>} />
                        <Route path="/current/:current_id" element={<ProtectedRoute><CurrentDetailView /></ProtectedRoute>} />
                        <Route path="/settings" element={<ProtectedRoute><SettingsForm /></ProtectedRoute>} />
                    </Routes>
                </div>
            </AuthProvider>
        </Router>
    );
}

export default App;