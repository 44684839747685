import React, { useState, useEffect, useCallback } from 'react';
import { getAllAppointments, deleteAllAppointments } from '../../services/appointmentService';
import { triggerScheduler } from '../../services/scheduleService';
import { exportAppointmentsToCSV } from '../../services/exportService';
import './AppointmentListView.css'; 

function formatTime(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    minutes = minutes.length < 2 ? '0' + minutes : minutes;
    hours = hours.length < 2 ? '0' + hours : hours;
    return hours + ':' + minutes;
}

function AppointmentListView() {
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [lastGenerated, setLastGenerated] = useState(null);

    const fetchAppointments = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getAllAppointments();
            updateAppointmentsData(response.data);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch appointments');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAppointments();
    }, [fetchAppointments]);

    const updateAppointmentsData = (data) => {
        const fetchedAppointments = data;
        // Ensure the appointments are loaded and there is at least one appointment
        if (fetchedAppointments.length > 0) {
            // Find the most recent updatedAt timestamp
            const latestUpdate = fetchedAppointments.reduce((latest, appointment) => {
                const appointmentDate = new Date(appointment.updatedAt);
                return appointmentDate > latest ? appointmentDate : latest;
            }, new Date(0)); // Initialize with the earliest possible date

            setLastGenerated(latestUpdate);
        }
        setAppointments(fetchedAppointments);
    };

    const sortedAppointments = React.useMemo(() => {
        return [...appointments].sort((a, b) => {
            if (!sortField) return 0;
            const valueA = a[sortField];
            const valueB = b[sortField];
            return (valueA < valueB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });
    }, [appointments, sortField, sortDirection]);

    const requestSort = (key) => {
        const direction = sortField === key && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(key);
        setSortDirection(direction);
    };

    const handleGenerateClick = async () => {
        setLoading(true);
        try {
            await triggerScheduler();
            await fetchAppointments(); // Re-fetch appointments after generation
            setSuccessMessage('Schedule updated successfully!');
        } catch (error) {
            setError('Failed to start scheduler: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClearSchedule = async () => {
        setLoading(true);
        try {
            await deleteAllAppointments();
            setAppointments([]); // Clear appointments in the state
            setSuccessMessage('All appointments cleared successfully!');
        } catch (error) {
            setError(`Failed to clear schedule: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleExportClick = async () => {
        setLoading(true);
        try {
            const { success, message } = await exportAppointmentsToCSV();
            if (success) {
                setSuccessMessage('Appointments exported successfully!');
            } else {
                setError(`Failed to export appointments: ${message}`);
            }
        } catch (error) {
            setError(`Failed to export appointments: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div className="loading-screen">Loading schedule...</div>;
    if (error) return <div className="error-message">Error: {error}</div>;


    return (
        <div className="appointment-list-view">
            <h2>Scheduled appointments</h2>
            <button className="generate-button" onClick={handleGenerateClick}>Generate schedule</button>
            <button className="clear-button" onClick={handleClearSchedule}>Clear Schedule</button>
            <button className="export-button" onClick={handleExportClick}>Export to CSV</button>
            <table className="appointments-table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('appointment_id')}>AppointmentID {sortField === 'appointment_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('resident_id')}>Resident ID {sortField === 'resident_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('rota_id')}>Rota ID {sortField === 'rota_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        {/* <th onClick={() => requestSort('date')}>Date {sortField === 'date' && (sortDirection === 'asc' ? '▲' : '▼')}</th> */}
                        <th onClick={() => requestSort('start_time')}>Start Time {sortField === 'start_time' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('end_time')}>End Time {sortField === 'end_time' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('address_line_1')}>Address Line 1 {sortField === 'address_line_1' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        {/* <th onClick={() => requestSort('address_line_2')}>Address Line 2{sortField === 'address_line_2' && (sortDirection === 'asc' ? '▲' : '▼')}</th> */}
                        {/* <th onClick={() => requestSort('city')}>City {sortField === 'city' && (sortDirection === 'asc' ? '▲' : '▼')}</th> */}
                        <th onClick={() => requestSort('postcode')}>Postcode {sortField === 'postcode' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        {/* <th onClick={() => requestSort('country')}>Country {sortField === 'country' && (sortDirection === 'asc' ? '▲' : '▼')}</th> */}
                        <th onClick={() => requestSort('current_id')}>Current ID {sortField === 'current_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedAppointments.map((appointment) => (
                        <tr key={appointment.appointment_id}>
                            <td>{appointment.appointment_id}</td>
                            <td>{appointment.resident_id}</td>
                            <td>{appointment.rota_id}</td>
                            {/* <td>{appointment.date}</td> */}
                            <td>{formatTime(appointment.start_time)}</td>
                            <td>{formatTime(appointment.end_time)}</td>
                            <td>{appointment.address_line_1}</td>
                            {/* <td>{appointment.address_line_2}</td> */}
                            {/* <td>{appointment.city}</td> */}
                            <td>{appointment.postcode}</td>
                            {/* <td>{appointment.country}</td> */}
                            <td>{appointment.current_id}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="last-generated">
                {successMessage && <div className="success-message">{successMessage}</div>}
                Schedule last updated on: {lastGenerated ? new Date(lastGenerated).toLocaleString() : 'Loading...'}
            </div>
        </div>
    );
}

export default AppointmentListView;
