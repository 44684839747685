import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getWorkers, deleteWorker } from '../../services/workerService';
import './WorkerListView.css'; // Make sure to import the CSS file

function WorkerListView() {
    const [workers, setWorkers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        const fetchWorkers = async () => {
            try {
                const response = await getWorkers();
                setWorkers(response.data); // assuming response.data contains the array of workers
                setLoading(false);
                setError('');
            } catch (err) {
                console.error(err);
                setError('Failed to fetch workers');
                setLoading(false);
            }
        };
        
        fetchWorkers();
    }, []);

    const handleDelete = async (workerId) => {
        if (window.confirm('Are you sure you want to delete this worker?')) {
            try {
                await deleteWorker(workerId);
                setWorkers(workers.filter(worker => worker.worker_id !== workerId));
            } catch (error) {
                console.error('Error deleting worker', error);
                // Optionally, show an error message to the user
            }
        }
    };

    // Sorting logic
    const sortedWorkers = React.useMemo(() => {
        return [...workers].sort((a, b) => {
            if (!sortField) return 0; // No sorting applied if sortField is not set
            const valueA = a[sortField];
            const valueB = b[sortField];
            return (valueA < valueB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });
    }, [workers, sortField, sortDirection]);

    // Function to request a sort based on the field
    const requestSort = (key) => {
        const direction = sortField === key && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(key);
        setSortDirection(direction);
    };

    if (loading) return <div>Loading workers...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="worker-list-view">
            <h1>Workers</h1>
            <Link to="/worker/new" className="create-new-worker">Create New Worker</Link>
            <table className="workers-table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('worker_id')}>WorkerID {sortField === 'worker_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('first_name')}>First Name {sortField === 'first_name' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('last_name')}>Last Name {sortField === 'last_name' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('email')}>Email {sortField === 'email' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('role')}>Role {sortField === 'role' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('date_of_joining')}>Date of Joining {sortField === 'date_of_joining' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('team_id')}>TeamID {sortField === 'team_id' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedWorkers.map(worker => (
                        <tr key={worker.worker_id}>
                            <td>{worker.worker_id}</td>
                            <td>{worker.first_name}</td>
                            <td>{worker.last_name}</td>
                            <td>{worker.email}</td>
                            <td>{worker.role}</td>
                            <td>{new Date(worker.date_of_joining).toLocaleDateString()}</td>
                            <td>{worker.team_id}</td>
                            <td>
                                <Link to={`/worker/${worker.worker_id}`} className="view-details">View Details</Link>
                                <Link to={`/worker/edit/${worker.worker_id}`} className="edit">Edit</Link>
                                <Link onClick={() => handleDelete(worker.worker_id)} className="delete">Delete</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default WorkerListView;