import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BACKEND_API_URL}/residents`;

const authHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getAllResidents = () => axios.get(API_URL, { headers: authHeader() });
export const getResidentById = (residentId) => axios.get(`${API_URL}/${residentId}`, { headers: authHeader() });
export const createResident = (residentData) => axios.post(API_URL, residentData, { headers: authHeader() });
export const updateResidentById = (residentId, residentData) => axios.put(`${API_URL}/${residentId}`, residentData, { headers: authHeader() });
export const deleteResidentById = (residentId) => axios.delete(`${API_URL}/${residentId}`, { headers: authHeader() });
