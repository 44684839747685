import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BACKEND_API_URL}/settings`;

const authHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getAllSettings = () => axios.get(API_URL, { headers: authHeader() });
export const getSettingsById = (settingsConfigId) => axios.get(`${API_URL}/${settingsConfigId}`, { headers: authHeader() });
export const createSettings = (settingsData) => axios.post(API_URL, settingsData, { headers: authHeader() });
export const updateSettingsById = (settingsConfigId, settingsData) => axios.put(`${API_URL}/${settingsConfigId}`, settingsData, { headers: authHeader() });
export const deleteSettingsById = (settingsConfigId) => axios.delete(`${API_URL}/${settingsConfigId}`, { headers: authHeader() });
