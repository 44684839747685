import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSettingsById, updateSettingsById, createSettings } from '../../services/settingService';

function formatTime(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    minutes = minutes.length < 2 ? '0' + minutes : minutes;
    hours = hours.length < 2 ? '0' + hours : hours;
    return hours + ':' + minutes;
}

function SettingsForm() {
    const [settings, setSettings] = useState({
        AM_shift_start: '',
        AM_shift_end: '',
        lunch_shift_start: '',
        lunch_shift_end: '',
        PM_shift_start: '',
        PM_shift_end: '',
        evening_shift_start: '',
        evening_shift_end: '',
    });
    const [settingsConfigId, setSettingsConfigId] = useState("1"); // Default selection
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false); // New state for tracking update success
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch and display current settings for selected config ID
        getSettingsById(settingsConfigId)
            .then(response => {
                if (response.data) {
                    setSettings(response.data);
                }
            })
            .catch(error => console.error('Error fetching settings details', error));
    }, [settingsConfigId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "settingsConfigId") {
            setSettingsConfigId(value);
        } else {
            setSettings(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setUpdateSuccess(false); // Reset the success message before the new submission
    
        try {
            // Try to get the settings to see if they exist
            const response = await getSettingsById(settingsConfigId);
    
            if (response.data) {
                // Settings exist, so update them
                await updateSettingsById(settingsConfigId, settings);
            } else {
                // Settings do not exist, so create them
                await createSettings(settings); // Assuming createSettings doesn't need an ID
            }
    
            setUpdateSuccess(true); // Set success flag to true when operation is successful
            setTimeout(() => navigate('/settings'), 3000); // Redirect after a delay
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Settings not found, so create them
                try {
                    await createSettings(settings); // Assuming createSettings doesn't need an ID
                    setUpdateSuccess(true); // Set success flag to true when creation is successful
                    setTimeout(() => navigate('/settings'), 3000); // Redirect after a delay
                } catch (creationError) {
                    console.error('Error creating settings', creationError);
                    setUpdateSuccess(false); // Ensure success message is not shown if there's an error
                }
            } else {
                // Some other error occurred
                console.error('Error saving settings', error);
                setUpdateSuccess(false); // Ensure success message is not shown if there's an error
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Edit Settings</h2>
            <div>
                <label>Settings Config ID:</label>
                <select name="settingsConfigId" value={settingsConfigId} onChange={handleChange}>
                    <option value="1">1</option>
                </select>
            </div>
            <div>
                <label>AM Shift Start:</label>
                <input type="time" name="AM_shift_start" value={formatTime(settings.AM_shift_start) || ''} onChange={handleChange} />
            </div>
            <div>
                <label>AM Shift End:</label>
                <input type="time" name="AM_shift_end" value={formatTime(settings.AM_shift_end) || ''} onChange={handleChange} />
            </div>
            <div>
                <label>Lunch Shift Start:</label>
                <input type="time" name="lunch_shift_start" value={formatTime(settings.lunch_shift_start) || ''} onChange={handleChange} />
            </div>
            <div>
                <label>Lunch Shift End:</label>
                <input type="time" name="lunch_shift_end" value={formatTime(settings.lunch_shift_end) || ''} onChange={handleChange} />
            </div>
            <div>
                <label>PM Shift Start:</label>
                <input type="time" name="PM_shift_start" value={formatTime(settings.PM_shift_start) || ''} onChange={handleChange} />
            </div>
            <div>
                <label>PM Shift End:</label>
                <input type="time" name="PM_shift_end" value={formatTime(settings.PM_shift_end) || ''} onChange={handleChange} />
            </div>
            <div>
                <label>Evening Shift Start:</label>
                <input type="time" name="evening_shift_start" value={formatTime(settings.evening_shift_start) || ''} onChange={handleChange} />
            </div>
            <div>
                <label>Evening Shift End:</label>
                <input type="time" name="evening_shift_end" value={formatTime(settings.evening_shift_end) || ''} onChange={handleChange} />
            </div>
            <button type="submit" disabled={isSubmitting}>Update Settings</button>
            {updateSuccess && <div className="success-message">Settings successfully updated!</div>}
        </form>
    );
}

export default SettingsForm;