import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getRotaById } from '../../services/rotaService';
import './RotaDetailView.css'; // Make sure to import the CSS file

// Helper function to format date to hh:mm
function formatTime(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    minutes = minutes.length < 2 ? '0' + minutes : minutes;
    hours = hours.length < 2 ? '0' + hours : hours;
    return hours + ':' + minutes;
}

function RotaDetailView() {
    const [rota, setRota] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { rota_id } = useParams(); // Get the rota ID from the URL

    useEffect(() => {
        getRotaById(rota_id).then(response => {
            const formattedData = {
                ...response.data,
                start_time: formatTime(response.data.start_time),
                end_time: formatTime(response.data.end_time)
            };
            setRota(formattedData); // Set the formatted rota details
            setLoading(false);
            setError('');
        }).catch(err => {
            setError('Failed to fetch rota details');
            console.error(err);
            setLoading(false);
        });
    }, [rota_id]);

    if (loading) return <div>Loading rota details...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!rota) return <div>Rota not found</div>;

    return (
        <div>
            <h2 className="rota-detail-view">Rota Details</h2>
            {/* <p>Date: {rota.date}</p> */}
            <p>Type: {rota.type}</p>
            <p>Start Time: {rota.start_time}</p>
            <p>End Time: {rota.end_time}</p>
            <p>Worker ID: {rota.worker_id ? rota.worker_id : 'Unassigned'}</p>
            <Link to="/rotas">Back to Rotas List</Link>
            <Link to={`/rota/edit/${rota.rota_id}`} style={{marginLeft: '10px'}}>Edit Rota</Link>
        </div>
    );
}

export default RotaDetailView;