import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const userToken = localStorage.getItem('token');
    if (userToken) {
      // Assuming you store the user's data in the token, otherwise you may need to fetch user data here
      setCurrentUser({ token: userToken }); // Set currentUser with token or fetch additional user data
    }
  }, []);

  const login = (token, userData) => {
    localStorage.setItem('token', token);
    setCurrentUser(userData); // Set additional user data as needed
  };

  const logout = () => {
    localStorage.removeItem('token');
    setCurrentUser(null);
  };

  const value = {
    currentUser,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};