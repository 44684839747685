import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getResidentById, createResident, updateResidentById } from '../../services/residentService';

function ResidentForm() {
    const [resident, setResident] = useState({
        first_name: '',
        last_name: '',
        contact_number: '',
        date_of_birth: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        postcode: '',
        country: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { resident_id } = useParams();
    const navigate = useNavigate();
    const isNewResident = !resident_id;

    useEffect(() => {
        if (resident_id) {
            getResidentById(resident_id)
                .then(response => setResident(response.data))
                .catch(error => console.error('Error fetching resident details', error));
        }
    }, [resident_id]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setResident(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        try {
            let residentResponse;
            if (isNewResident) {
                residentResponse = await createResident(resident);
            } else {
                residentResponse = await updateResidentById(resident_id, resident);
            }

            if (residentResponse && residentResponse.data) {
                navigate('/residents');
            } else {
                console.error('Resident update/create failed with no response.');
            }
        } catch (error) {
            console.error('Error processing form', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>{isNewResident ? 'Create New Resident' : 'Edit Resident'}</h2>
            <div>
                <label>First Name:</label>
                <input type="text" name="first_name" value={resident.first_name} onChange={handleChange}  />
            </div>
            <div>
                <label>Last Name:</label>
                <input type="text" name="last_name" value={resident.last_name} onChange={handleChange} required />
            </div>
            <div>
                <label>Contact Number:</label>
                <input type="tel" name="contact_number" value={resident.contact_number} onChange={handleChange}  />
            </div>
            <div>
                <label>Date of Birth:</label>
                <input type="date" name="date_of_birth" value={resident.date_of_birth} onChange={handleChange}  />
            </div>
            <div>
                <label>Address Line 1:</label>
                <input type="text" name="address_line_1" value={resident.address_line_1} onChange={handleChange}  />
            </div>
            <div>
                <label>Address Line 2:</label>
                <input type="text" name="address_line_2" value={resident.address_line_2} onChange={handleChange} />
            </div>
            <div>
                <label>City:</label>
                <input type="text" name="city" value={resident.city} onChange={handleChange}  />
            </div>
            <div>
                <label>Postcode:</label>
                <input type="text" name="postcode" value={resident.postcode} onChange={handleChange}  />
            </div>
            <div>
                <label>Country:</label>
                <input type="text" name="country" value={resident.country} onChange={handleChange}  />
            </div>
            <button type="submit" disabled={isSubmitting}>{isNewResident ? 'Create' : 'Update'} Resident</button>
        </form>
    );
}

export default ResidentForm;
